.InputText_Label {
	cursor : pointer;
	font-size : 16px;
	line-height : 19px;
	color : #A0A2C0;
	display : block;
}

.InputText_Wrapper {
	position : relative;
}

.InputText_Input {
	font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
	font-weight: 300;
	width: 100%;
	height: auto;
	background-color: #ffffff;
	border-radius: 5px;
	text-align: left;
	display: block;
	padding: 11px 20px 11px 20px;
	margin: 0;
	font-size: 21px;
	line-height: 26px;
	color: #37694A;
	letter-spacing: 2px;
	border-color: #DDDDDD;
}

