.Menu {
    padding-top: 50px;
}

.MenuItem {
    padding: 5px 10px;
    border-radius: 7px;
}

.MenuItem:hover {
    background-color: #fafafa;
}

.Panel {
    /*background-color: #ff00ae;*/
}

.TitleWrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    color: #EA7E3D;
    font-size: 21px;
}

.SubTitleWrapper {
    padding-bottom: 5px;
    color: #606060;
    font-size: 21px;
    display: inline-block;
    border-bottom: 1px #bbbaba solid;
    width: 100%;
    margin-bottom: 20px;
}

.InputWrapper {
    padding-bottom: 5px;
}

.LastInputWrapper {
    padding-bottom: 50px;
}

.ButtonWrapper {
    margin-top: 20px;
    width: 250px;
}

.ButtonWrapperFullWidth {
    margin-top: 20px;
    width: 100%;
}

.Label {
    cursor: pointer;
    font-size: 15px;
    line-height: 19px;
    color: #606060;
    display: block;
    margin-bottom: 7px;
}

.AssignButton {
    width:60%;
    font-size: 22px;
    float: right;
}

.CancelButton {
    width:39%;
    font-size: 22px;
}

.FullWidth {
    width:100%;
    margin-bottom: 10px;
}

.SuccessMessage {
    color: #606060;
    margin: 0;
    padding: 0 5px 0 5px;
    text-align: center;
}

.ErrorMessage {
    color: #EA7E3D;
    margin: 0;
    padding: 0 5px 0 5px;
}

.SelectedMenuItem {
    background-color: #eeeeee;
}

.Selected {
    font-size: 17px;
    color: #EA7E3D;
}

.Selected:focus {
    color: #EA7E3D;
}

.Selected:hover {
    color: #EA7E3D;
}

.Unselected {
    font-size: 17px;
    padding-bottom: 10px;
    color: #606060;
}

.Unselected:hover {
    color: #606060;
}

.TextBlock {
    font-size: 15px;
    line-height: 19px;
    color: #606060;
    display: block;
}

.HighlightTextBlock {
    font-size: 15px;
    line-height: 19px;
    color: #EA7E3D;
    display: block;
}
