.TitleWrapper {
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
}

.LinkWrapper {
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
}

.LinkWrapper:hover {
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
}

.Arrow {
    vertical-align: top;
    line-height: 26px;
    font-weight: 500;
    font-size: 26px;
}

.Participant {
    margin-bottom: 15px;
}

.NameText {
    color: #606060;
    font-size: 15px;
}

.BioText {
    color: #606060;
    font-size: 15px;
    font-weight: 300;
}
