.TitleWrapper {
    padding-top: 50px;
    padding-bottom: 22px;
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
}

.LinkWrapper {
    padding-top: 50px;
    padding-bottom: 22px;
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
}

.LinkWrapper:hover {
    padding-top: 50px;
    padding-bottom: 22px;
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
}

.Arrow {
    vertical-align: top;
    line-height: 26px;
    font-weight: 500;
    font-size: 26px;
}

.InstructionsText {
    font-size: 21px;
    color: #606060;
    font-weight: 300;
}

.PodName {
    font-size: 15px;
    color: #606060;
    font-weight: bold;
}

.MeetingDateTime {
    font-size: 15px;
    color: #606060;
    font-weight: 300;
}

.CardLink {
    font-size: 19px;
    line-height: 17px;
    font-weight: 300;
    color: #37694A;
    text-transform: uppercase;
    border: 1px solid #37694A;
    border-radius: 1px;
    padding: 6px 24px;
    margin-left: auto;
}

.CardLink:hover {
    background-color: #37694A;
    color: #fff;
}

.CardLinkWrapper {
    text-align: right;
}

.JoinWidth {
    width: 140px;
    display: block;
    text-align: center;
    line-height: 21px;
    margin-bottom: 5px;
}

.WaitlistWidth {
    width: 180px;
    display: block;
    text-align: center;
    line-height: 21px;
    margin-bottom: 5px;
}

.LastInputWrapper {
    font-size: 21px;
    font-weight: 300;
    padding-bottom: 20px;
    color: #606060;
}