.Menu {
    padding-top: 50px;
}

.MenuItem {
    padding: 10px;
    border-radius: 10px;
}

.Panel {
    /*background-color: #ff00ae;*/
}

.TitleWrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    color: #EA7E3D;
    font-size: 21px;
}

.InputWrapper {
    padding-bottom: 5px;
}

.Label {
    cursor: pointer;
    font-size: 15px;
    line-height: 19px;
    color: #606060;
    display: block;
    margin-bottom: 7px;
}

.AssignButton {
    width:60%;
    font-size: 22px;
    float: right;
}

.CancelButton {
    width:39%;
    font-size: 22px;
}

.FullWidth {
    width:100%;
    margin-bottom: 10px;
}

.SuccessMessage {
    color: #606060;
    margin: 0;
    padding: 0 5px 0 5px;
    text-align: center;
}

.ErrorMessage {
    color: #EA7E3D;
    margin: 0;
    padding: 0 5px 0 5px;
}

.SelectedMenuItem {
    background-color: #eeeeee;
}

.Selected {
    font-size: 21px;
    color: #EA7E3D;
}

.Selected:focus {
    color: #EA7E3D;
}

.Selected:hover {
    color: #EA7E3D;
}

.Unselected {
    font-size: 21px;
    padding-bottom: 10px;
    color: #606060;
}
