.ButtonDanger {
	font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
	text-align : center;
	border-radius : 5px;
	border-color: #606060;
	background-color: #3b3b3b;
	padding : 11px 30px 11px 30px;
	font-size : 22px;
	line-height : 27px;
	color : #ffffff;
	cursor : pointer;
}

.ButtonDanger:hover {
	background-color: #ea3d3d;
	color : #ffffff;
	border-color: #ffffff;
}