.TitleWrapper {
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
}

.LinkWrapper {
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
}

.LinkWrapper:hover {
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
}

.Subtitle {
    padding-top: 10px;
    padding-bottom: 12px;
    color: #F4B144;
    font-size: 15px;
}

.Paragraph {
    padding-bottom: 20px;
    color: #000000;
    font-size: 19px;
    font-weight: 300;
}

.Subparagraph {
    padding-bottom: 20px;
    color: #000000;
    font-size: 14px;
    font-weight: 300;
}

.StatusDropdown {
    padding-top: 10px;
}

.StatusDropdownLabel {
    padding-bottom: 20px;
    color: #F4B144;
}

.Arrow {
    vertical-align: top;
    line-height: 26px;
    font-weight: 500;
    font-size: 26px;
}

.InstructionsText {
    font-size: 21px;
    color: #606060;
    font-weight: 300;
    padding-bottom: 30px;
}

.InputWrapper {
    padding-bottom: 5px;
}

.Label {
    cursor: pointer;
    font-size: 15px;
    line-height: 19px;
    color: #606060;
    display: block;
    margin-bottom: 7px;
}


.DoneLabel {
    cursor: pointer;
    font-size: 17px;
    line-height: 19px;
    color: #606060;
}

.ErrorMessage {
    color: #EA7E3D;
    margin: 0;
    padding: 0 5px 0 5px;
}

.FullWidthButton {
    width: 100%;
}

.AlignRight {
    float: right;
}
