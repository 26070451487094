.TitleWrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
}

.SubTitleWrapper {
    padding-top: 20px;
    padding-bottom: 5px;
    color: #606060;
    font-size: 21px;
    display: inline-block;
    border-bottom: 1px #bbbaba solid;
    width: 100%;
    margin-bottom: 20px;
}

.LinkWrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
}

.LinkWrapper:hover {
    padding-top: 50px;
    padding-bottom: 50px;
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
}

.InputWrapper {
    padding-bottom: 30px;
}

.Arrow {
    vertical-align: top;
    line-height: 26px;
    font-weight: 500;
    font-size: 26px;
}

.BodyText {
    font-size: 21px;
    color: #606060;
    margin-bottom: 10px;
    font-weight: 300;
}

.ButtonWrapper {
    width: 100%;
    margin-bottom: 10px;
}

.MessageText {
    text-align: center;
    color: #606060;
}

.FullWidth {
    width:100%;
    margin-bottom: 10px;
}

.AssignButton {
    width:fit-content;
    min-width: 200px;
    font-size: 22px;
    float: right;
    margin-left: 8px;
}

.CancelButton {
    width:fit-content;
    min-width: 200px;
    font-size: 22px;
}

.ErrorMessage {
    color: #EA7E3D;
    margin: 0;
    padding: 0 5px 0 5px;
}
