.TitleWrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
}

.SubTitleText {
    font-size: 15px;
    color: #78906B;
    font-weight: bold;
    padding-bottom: 10px;
}

.LinkWrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
}

.LinkWrapper:hover {
    padding-top: 50px;
    padding-bottom: 50px;
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
}

.Arrow {
    vertical-align: top;
    line-height: 26px;
    font-weight: 500;
    font-size: 26px;
}

.BodyText {
    font-size: 21px;
    color: #606060;
    margin-bottom: 10px;
    font-weight: 300;
}

.DateText {
    font-size: 17px;
    color: #606060;
    font-weight: 300;
    margin-bottom: 5px;
}

.StatsText {
    font-size: 15px;
    color: #606060;
    font-weight: 300;
    margin-bottom: 5px;
}

.ButtonWrapper {
    width: 100%;
    margin-bottom: 10px;
}

.ShareWinButtonWrapper {
    width: 100%;
    margin-bottom: 10px;
    background-color: #37694A;
}

.MessageText {
    text-align: center;
    color: #606060;
}