.ContentWrapper {
    background-color: #606060;
}

.TitleWrapper {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 90px;
    color: #ffffff;
    font-size: 29px;
    text-decoration: underline;
    font-weight: 300;
}

.HeaderText {
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 18px;
    font-weight: bold;
}

.BodyText {
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 18px;
    font-weight: 300;
}