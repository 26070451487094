input[type="file"] {
    display: none;
}


.Avatar {
    cursor: pointer;
    width: 256px;
    height: 256px;
    box-sizing: border-box;
    border: 2px solid transparent;
}

.Text {
    font-size: 9px;
    color: #37694A;
    font-weight: bold;
}
