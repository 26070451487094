.TitleWrapper {
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
}

.SubTitleWrapper {
    padding-bottom: 10px;
    color: #606060;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
}

.LinkWrapper {
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
}

.LinkWrapper:hover {
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
}

.Buffer {
    padding-bottom: 50px;
}

.Arrow {
    vertical-align: top;
    line-height: 26px;
    font-weight: 500;
    font-size: 26px;
}

.Participant {
    margin-bottom: 15px;
}

.NameText {
    font-size: 15px;
    color: #606060;
    padding-bottom: 3px;
}

.LeaderText {
    color: #37694A;
    font-size: 15px;
    display: block;
    padding-bottom: 5px;
}

.PodName {
    color: #EA7E3D;
    font-size: 19px;
}

.PodMeeting {
    color: #606060;
    font-size: 15px;
}

.PodSize {
    color: #606060;
    font-size: 15px;
    font-weight: 300;
    padding-bottom: 5px;
}

.LeaderBadge {
    vertical-align: middle;
}

.Dropdown {
    padding-top: 50px;
    width: 100%;
}

.DropdownLabel {
    margin-bottom: 15px;
}

.Link {
    font-size: 15px;
    text-decoration: underline;
}
