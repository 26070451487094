.Header {
    text-align: left;
    display: block;
}

.SignUp {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.SignUpForm {
    border-radius: 6px;
}

.TitleWrapper {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 21px;
    color: #EA7E3D;
    font-size: 21px;
}

.AvatarWrapper {
    text-align: center;
    padding-bottom: 13px;
}

.InputWrapper {
    padding-bottom: 10px;
}

.LastInputWrapper {
    padding-bottom: 16px;
}

.ButtonWrapper {
    margin-bottom: 7px;
}

.CancelButtonWrapper {
    width: 100%;
}

.BottomLinkContainer {
    padding-top: 50px;
    width: 100%;
    text-align: center;
}

.BottomLinkText {
    color: #37694A;
    font-weight: 200;
    font-size: 17px;
    line-height: 20px;
    text-decoration: underline;
}

.BottomLinkText:hover {
    color: #EA7E3D;
}

.CheckboxWrapper {
    padding-bottom: 10px;
}

.CheckboxWrapperLast {
    padding-bottom: 30px;
}

.Checkbox {

}

.Underline {
    padding-left: 4px;
    font-size : 12px;
    line-height : 19px;
    color : #606060;
    display : inline-block;
    cursor: pointer;
    text-decoration: underline;
}

.ButtonBack {
    min-width: 98px;
}

.FullWidthButton {
    width: 100%;
}

.ErrorMessage {
    color: #EA7E3D;
    margin: 0;
    padding: 0 5px 0 5px;
}
