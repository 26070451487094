.TitleWrapper {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    color: #EA7E3D;
    font-size: 30px;
}

.CheckInboxText {
    text-align: center;
    font-size: 21px;
    color: #606060;
    margin-bottom: 50px;
    font-weight: 300;
}

.OkayButtonWrapper {
    width: 100%;
}

.FullWidthButton {
    width: 100%;
}