.Header {
	background-color : #ffffff;
	position: sticky; top: 0;
	width: 100%;
	z-index: 1000;
}

.LogoWrapper {
	width: 100%;
	text-align : center;
	padding : 16px 16px 15px 16px;
}

.LogoInnerWrapper {
	width: 50%;
	margin: 0 auto;
}

.LoginButtonWrapper {
	padding : 10px 10px;
}

.ButtonPrimary {
	min-width: 98px;
}
