.Select_Label {
    cursor: pointer;
    font-size: 15px;
    line-height: 19px;
    color: #606060;
    display: block;
    margin-bottom: 9px;
    padding-top: 1px;
}

.Select_Wrapper {
    overflow: hidden;
    position: relative;
}

.Select_Label_Padding {
    padding-bottom: 5px;
}

.Select_Select {
    font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size: 21px;
    line-height: 26px;
    font-weight: 300;
    color: #37694A;
    letter-spacing: 2px;
    border-color: #DDDDDD;
    border-radius: 5px;
    appearance: none;
    width: 100%;
    height: auto;
    margin-bottom: 0;
    padding: 11px 0 11px 20px;
}

.Select_Slim {
    font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size: 19px;
    line-height: 20px;
    font-weight: 300;
    color: #37694A;
    letter-spacing: 2px;
    border-color: #DDDDDD;
    border-radius: 5px;
    appearance: none;
    width: 100%;
    height: auto;
    margin-bottom: 0;
    padding: 5px 0 5px 20px;
}


.Select_Icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}

