.TitleWrapper {
    padding-top: 50px;
    padding-bottom: 12px;
    color: #EA7E3D;
    font-size: 21px;
}

.SubTitle {
    padding-bottom: 20px;
    color: #606060;
    font-size: 17px;
    font-weight: bold;
}

.AddPodWrapper {
    width: 45%;
}

.JoinPodWrapper {
    width: 45%;
    margin-left: 20px;
}

.MembershipCardWrapper {
    background-color: white;
    color: #37694A;
    padding: 10px 13px 2px;
    margin-top: 10px;
    width: 100%;
    border-radius: 5px;
}

.MembershipCardTitle {
    font-size: 18px;
    line-height: 29px;
    font-weight: 500;
    padding-bottom: 34px;
}

.MembershipSettings {
    float: right;
    width: 20px;
    margin-top: -10px;
}

.MembershipCardItem {
    padding-bottom: 12px;
}

.MembershipCardLink {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: #606060;
    text-decoration: underline;
}

.Icon {
    float: right;
    margin-top: 3px;
}

.IconMiddle {
    float: right;
    margin-top: 9px;
}