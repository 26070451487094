.TitleWrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    color: #EA7E3D;
    font-size: 21px;
}

.InviteAdminText {
    text-align: center;
    font-size: 21px;
    color: #606060;
    margin-bottom: 50px;
    font-weight: 300;
}

.InviteForm {

}

.InputWrapper {
    padding-bottom: 10px;
}

.LastInputWrapper {
    padding-bottom: 30px;
}

.AssignButton {
    width:60%;
    font-size: 22px;
    float: right;
}

.CancelButton {
    width:39%;
    font-size: 22px;
}

.FullWidth {
    width:100%;
    margin-bottom: 10px;
}

.ErrorMessage {
    color: #EA7E3D;
    margin: 0;
    padding: 0 5px 0 5px;
}
