.Checkbox_Label {
	font-size : 12px;
	line-height : 19px;
	color : #606060;
	display : inline-block;
	cursor: pointer;
}

.Checkbox_Label_Text {
	padding-left: 16px;
}

.Checkbox_Standard {
	display : none;
}

.Checkbox_Standard + .Checkbox_Custom {
	float : left;
	width : 20px;
	height : 18px;
	background-image: url('../svg/Unchecked.svg');
}

.Checkbox_Standard:checked + .Checkbox_Custom {
	background-image: url('../svg/Checked.svg');
}

.Underline {
	text-decoration: underline;
}

