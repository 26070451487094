.Header {
    text-align: left;
    display: block;
}

.SignIn {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.UserForm {
    margin-top: 100px;
    border-radius: 6px;
}

.TitleWrapper {
    text-align: center;
    padding-bottom: 23px;
}

.UsernameWrapper {
    padding-bottom: 10px;
}

.EmailWrapper {
    padding-bottom: 20px;
}

.ButtonWrapper {
    margin-bottom: 7px;
}

.CancelButtonWrapper {
    width: 100%;
}

.ForgotPasswordContainer {
    padding-top: 50px;
    width: 100%;
    text-align: center;
}

.ForgotPasswordText {
    color: #37694A;
    font-weight: 200;
    font-size: 17px;
    line-height: 20px;
    text-decoration: underline;
}


.ForgotPasswordText:hover {
    color: #EA7E3D;
}

.ButtonBack {
    min-width: 98px;
}

.FullWidthButton {
    width: 100%;
}

.ErrorMessage {
    color: #EA7E3D;
    margin: 0;
    padding: 0 5px 0 5px;
}
