.TitleWrapper {
    padding-top: 50px;
    padding-bottom: 22px;
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
}

.LinkWrapper {
    padding-top: 50px;
    padding-bottom: 22px;
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
}

.LinkWrapper:hover {
    padding-top: 50px;
    padding-bottom: 22px;
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
}

.SubTitle {
    padding-top: 20px;
    padding-bottom: 12px;
    color: #606060;
    font-size: 17px;
}

.Arrow {
    vertical-align: top;
    line-height: 26px;
    font-weight: 500;
    font-size: 26px;
}

.InputWrapper {
    padding-bottom: 5px;
}

.Label {
    cursor: pointer;
    font-size: 15px;
    line-height: 19px;
    color: #606060;
    display: block;
    margin-bottom: 7px;
}

.AssignButton {
    width:60%;
    font-size: 22px;
    float: right;
}

.CancelButton {
    width:39%;
    font-size: 22px;
}

.FullWidth {
    width:100%;
    margin-bottom: 10px;
}

.SuccessMessage {
    color: #606060;
    margin: 0;
    padding: 0 5px 0 5px;
    text-align: center;
}

.ErrorMessage {
    color: #EA7E3D;
    margin: 0;
    padding: 0 5px 0 5px;
}
