.TitleWrapper {
    padding-top: 50px;
    padding-bottom: 12px;
    color: #EA7E3D;
    font-size: 21px;
}

.NoNotifications {
    color: #606060;
    margin-top: 10px;
    width: 100%;
}

.CardWrapper {
    color: #606060;
    margin-top: 10px;
    width: 100%;
    border-bottom: 1px solid #B2B2B2;
}

.CardTitle {
    font-size: 15px;
    line-height: 35px;
    font-weight: bold;
    padding-bottom: 13px;
}

.CardMessage {
    font-size: 15px;
    line-height: 24px;
    padding-bottom: 20px;
}

.CardItem {
    text-align: right;
    margin-bottom: 20px;
    white-space: nowrap;
}

.CardLink {
    font-size: 19px;
    line-height: 17px;
    font-weight: 300;
    color: #37694A;
    text-transform: uppercase;
    border: 1px solid #37694A;
    border-radius: 1px;
    padding: 6px 24px;
}

.CardLink:hover {
    background-color: #37694A;
    color: #fff;
}

.FullWidth {
    width: 100%;
    display: block;
    text-align: center;
    line-height: 21px;
    margin-bottom: 5px;
}

.SecondaryCardLink {
    font-size: 19px;
    line-height: 17px;
    font-weight: 300;
    color: #606060;
    text-transform: uppercase;
    border: 1px solid #606060;
    border-radius: 1px;
    padding: 6px 24px;
    margin-right: 10px;
}

.SecondaryCardLink:hover {
    background-color: #606060;
    color: #fff;
}

.Icon {
    float: right;
    margin-top: 3px;
}