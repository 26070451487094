.InputText_Wrapper {
    position: relative;
    padding-top: 2px;
    font-size: 24px;
    display: inline-block;
    width: 100%;
}

.Time_Wrapper {
    padding-left: 15px;
    float: right;
}

.InputText_Label {
    cursor : pointer;
    font-size : 16px;
    line-height : 19px;
    color : #606060;
    display : block;
    margin-bottom : 9px;
}

.Separator {
    display: inline-block;
    padding-left: 2px;
    padding-right: 2px;
}

.InputText_Input {
    font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif !important;
    font-weight: 300 !important;
    height: auto;
    background-color: #ffffff;
    border-radius: 5px;
    display: inline-block;
    padding: 11px 10px 11px 10px !important;
    margin: 0;
    font-size: 21px;
    line-height: 26px;
    color: #37694A;
    letter-spacing: 2px;
    border-color: #DDDDDD;
}

.DatePopper {
    width: max-content;
}

.InputText_Month, .InputText_Day {
    width: 60px;
}

.InputText_Year {
    width: 80px;
}

.InputText_Time {
    width: 50px;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #939393;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #939393;
    opacity: 1;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #939393;
    opacity: 1;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #939393;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #939393;
}

::placeholder { /* Most modern browsers support this now. */
    color: #939393;
}