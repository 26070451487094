.react-datepicker__input-container > input {
    font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
    font-size: 21px;
    line-height: 26px;
    color: #37694A;
    letter-spacing: 2px;
    border-radius: 5px;
    padding:20px;
}

.react-datepicker__triangle {
    display: none;
}