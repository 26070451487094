.TitleWrapper {
    padding-top: 50px;
    padding-bottom: 26px;
    color: #EA7E3D;
    font-size: 21px;
}

.SubHeadingText {
    font-size: 21px;
    color: #606060;
    margin-bottom: 20px;
    font-weight: 300;
}

.InputWrapper {

}

.LastInputWrapper {
    padding-bottom: 20px;
}

.AssignButton {
    width:60%;
    font-size: 22px;
    float: right;
}

.CancelButton {
    width:39%;
    font-size: 22px;
}

.FullWidth {
    width:100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.ErrorMessage {
    color: #EA7E3D;
    margin: 0;
    padding: 0 5px 0 5px;
}

.SuccessMessage {
    color: #000000;
    margin: 0;
    padding: 0 5px 0 5px;
    text-align: right;
}
