.ButtonCancel {
	font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
	text-align : center;
	border-radius : 5px;
	background-color: #606060;
	padding : 11px 0 11px 0;
	font-size : 22px;
	line-height : 27px;
	color : #FFFFFF;
	cursor : pointer;
}

