.TitleWrapper {
    padding-top: 50px;
    padding-bottom: 22px;
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
}

.SubTitle {
    padding-top: 20px;
    padding-bottom: 12px;
    color: #606060;
    font-size: 17px;
}

.Arrow {
    vertical-align: top;
    line-height: 26px;
    font-weight: 500;
    font-size: 26px;
}

.LinkWrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
}

.LinkWrapper:hover {
    padding-top: 50px;
    padding-bottom: 50px;
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
}

.CardWrapper {
    background-color: white;
    color: #37694A;
    padding-top: 10px;
    padding-left: 13px;
    padding-right: 13px;
    padding-bottom: 2px;
    margin-top: 10px;
    width: 100%;
    border-radius: 5px;
}

.AddPodWrapper {
    width: 45%;
    margin-right: 20px;
}

.JoinPodWrapper {
    width: 45%;
}

.MembershipCardWrapper {
    background-color: white;
    color: #37694A;
    padding: 10px 13px 2px;
    margin-top: 10px;
    width: 100%;
    border-radius: 5px;
}

.CardTitle {
    font-size: 18px;
    line-height: 29px;
    font-weight: 500;
    padding-bottom: 12px;
}

.CardItem {
    padding-bottom: 12px;
}

.CardLink {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: #606060;
    text-decoration: underline;
}

.QueueText {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: #606060;
    padding-top: 5px;
    padding-bottom: 10px;
}

.EditLink {
    float: right;
}

.Edit {
    margin-top: 4px;
    margin-right: 10px;
    display: inline-block;
    text-decoration: underline;
}

.CardDateAndTime {
    font-size: 15px;
    line-height: 21px;
    font-weight: 400;
    color: #B2B2B2;
}

.Icon {
    float: right;
    margin-top: 3px;
}