.Title {
    padding-top: 50px;
    padding-bottom: 22px;
}

.TitleWrapper {
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
}

.SubTitleWrapper {
    padding-top: 20px;
    padding-bottom: 5px;
    color: #606060;
    font-size: 21px;
    display: inline-block;
    border-bottom: 1px #bbbaba solid;
    width: 100%;
    margin-bottom: 20px;
}

.HeadingWrapper {
    padding-top: 5px;
    padding-bottom: 5px;
    color: #606060;
    font-size: 21px;
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
    font-weight: 300;
}

.SubHeadingWrapper {
    color: #606060;
    font-size: 18px;
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
}

.LinkWrapper {
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
}

.LinkWrapper:hover {
    color: #EA7E3D;
    font-size: 21px;
    display: inline-block;
    margin-right: 8px;
}

.Arrow {
    vertical-align: top;
    line-height: 26px;
    font-weight: 500;
    font-size: 26px;
}

.NoNotifications {
    color: #606060;
    margin-top: 10px;
    width: 100%;
}

.ChatWrapper {
    padding: 10px;
    border-radius: 5px;
    border: #606060 solid 1px;
    margin-bottom: 10px;
}

.ChatWrapper:hover {
    background-color: #a8a8a8;
    color: white;
}

.UnreadChatWrapper {
    padding: 10px;
    border-radius: 5px;
    border: #606060 solid 1px;
    margin-bottom: 10px;
    font-weight: bold;
}

.UnreadChatWrapper:hover {
    background-color: #a8a8a8;
    color: white;
    font-weight: bold;
}

.ChatTitle {
    color: #37694A;
    font-size: 15px;
}

.ChatTitle:hover {
    color: #4a8d64;
    font-size: 15px;
}

.FullWidth {
    width: 100%;
    display: block;
    text-align: center;
    line-height: 21px;
    margin-bottom: 5px;
}

.InputWrapper {
    padding-bottom: 10px;
}

.FormWrapper {
    padding-top: 10px;
    padding-bottom: 20px;
}

.ErrorMessage {
    color: #EA7E3D;
    margin: 0;
    padding: 0 5px 0 5px;
}

.ChatInput {
    flex: 1;
    flex-basis:0;
    overflow: auto;
    scroll-behavior: smooth;
}

.UserMessageWrapper {
    color: #37694A;
}

.MessageWrapper {
    padding-bottom: 20px;
}

.LastRead {
    border-bottom: 1px #bbbaba solid;
    color: #606060;
    font-size: 11px;
    padding-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.NameWrapper {
    color: #EA7E3D;
    font-weight: bold;
}

.GroupMessageWrapper {
    color: #606060;
}

.MessageTime {
    color: #606060;
    font-size: 9px;
}

.SenderWrapper {
    margin-left: 40px;
}

.FloatRight {
    float: right;
}

.Icon {
    float: right;
    margin-top: 3px;
}