.InputText_Wrapper {
    position: relative;
    padding-top: 2px;
}

.InputText_Label {
    cursor : pointer;
    font-size : 15px;
    line-height : 19px;
    color : #606060;
    display : block;
    margin-bottom : 9px;
}

.InputText_Input {
    font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border-radius: 5px;
    text-align: left;
    display: block;
    padding: 11px 10px 11px 10px;
    margin: 0;
    font-size: 21px;
    line-height: 26px;
    color: #37694A;
    letter-spacing: 2px;
    border-color: #DDDDDD;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #37694A;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #37694A;
    opacity: 1;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #37694A;
    opacity: 1;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #37694A;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #37694A;
}

::placeholder { /* Most modern browsers support this now. */
    color: #37694A;
}