html, body, #root {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
}

body {
    margin: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #DDDDDD;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (min-width: 64em) {
    .row {
        max-width: 1004px
    }
}
